import { useCompositionEndValueSync } from 'hooks'
import { ChangeEvent, forwardRef, useCallback, useState } from 'react'
import { useObjectRef } from 'react-aria'
import { NumberFormatBase, useNumericFormat } from 'react-number-format'
import { removeComma } from 'string'
import { isNotNil } from 'typeguards'
import { reassignEventTargetToCurrentTarget } from '../../../utils'
import { TextInputBoxProps } from '../types'
import { getCommasCount } from '../utils/get-commas-count'
import { getDiffIndexes } from '../utils/get-diff-indexes'
import { normalizeNumericString } from '../utils/normalize-numeric-string'

export type FormattedNumberInputProps = Omit<TextInputBoxProps, 'pattern'> & {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const FormattedNumberInput = forwardRef<HTMLInputElement, FormattedNumberInputProps>(
  (
    {
      id,
      value,
      onChange,
      onPaste,
      autoComplete = 'off',
      thousandSeparator = true,
      isComposing,
      style,
      ...props
    },
    ref
  ) => {
    const inputRef = useObjectRef(ref)
    const [lastCaretPosition, setLastCaretPosition] = useState<number | null>(null)
    const composingFormattedValueRef = useCompositionEndValueSync({
      isComposing,
      id,
      value,
      onChange,
      name: props.name,
    })
    const { format, ...rest } = useNumericFormat({
      ...props,
      thousandSeparator: Boolean(thousandSeparator),
      thousandsGroupStyle: 'thousand',
      allowNegative: true,
      type: 'text',
      defaultValue: props.defaultValue?.toString() ?? undefined,
    })
    const compositionValueFormatter = useCallback(
      // DO NOT CHANGE THIS METHOD: THIS PLACE IS THE SOURCE OF ANY POSSIBLE OS COMPOSITION BUG
      (val: string) => {
        if (!isComposing) {
          return format?.(normalizeNumericString(val)) ?? ''
        }
        return val
      },
      [isComposing, format]
    )

    const handleValueChange = useCallback(
      ({ formattedValue }: { formattedValue: string }) => {
        if (isComposing) {
          composingFormattedValueRef.current = formattedValue
          const nonFormattedStringsCaretIndex = getDiffIndexes(
            String(removeComma(value)),
            removeComma(formattedValue)
          )[1]

          const commasCount = getCommasCount(formattedValue, nonFormattedStringsCaretIndex)
          setLastCaretPosition(nonFormattedStringsCaretIndex + commasCount)

          return
        }
        composingFormattedValueRef.current = null

        onChange({
          target: {
            value: formattedValue !== '' ? formattedValue : undefined,
            id: id ?? '',
            name: props.name ?? '',
          },
        } as ChangeEvent<HTMLInputElement>)
        // THIS FIXES THE ISSUE WITH CARET POSITION AFTER COMPOSITION IN CONTROLLED INSTANCES
        if (isNotNil(lastCaretPosition) && !Number.isNaN(lastCaretPosition)) {
          const targetCaretPosition = formattedValue.length - lastCaretPosition
          setTimeout(() => {
            inputRef.current?.setSelectionRange(targetCaretPosition, targetCaretPosition)
          }, 0)
          setLastCaretPosition(null)
        }
      },
      [
        id,
        isComposing,
        onChange,
        props.name,
        lastCaretPosition,
        inputRef,
        value,
        composingFormattedValueRef,
      ]
    )

    return (
      <NumberFormatBase
        {...props}
        {...rest}
        autoComplete={autoComplete}
        defaultValue={props.defaultValue?.toString() ?? undefined}
        id={id}
        onPaste={onPaste}
        style={style}
        format={compositionValueFormatter}
        onValueChange={handleValueChange}
        removeFormatting={(val) => val}
        type="text"
        inputMode="decimal"
        value={value?.toString() ?? undefined}
        getInputRef={inputRef}
        valueIsNumericString
        onFocus={(e) => reassignEventTargetToCurrentTarget(e, props.onFocus)}
        onBlur={(e) => reassignEventTargetToCurrentTarget(e, props.onBlur)}
      />
    )
  }
)
FormattedNumberInput.displayName = 'FormattedNumberInput'
